/* global $ */
import '../../css/app/components/home.scss';
import '../../css/app/components/review-slider.scss';
class Home
{
    constructor () 
    {
        this.initCarousels();
        this.initMatchHeight();
    }
    initCarousels() {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.slick-carousel').slick();
            $('.brands-carousel').slick({
                autoplay: false,
                arrows: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
            $('.review-carousel').slick({
                autoplay: false,
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        });
    }
    initMatchHeight() {
        if ($(".review-content-height").length) {
            import(/* webpackChunkName: "matchheight" */ 'jquery-match-height').then(() => {
                $('.review-content-height').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });
            });
        }
    }
}

new Home;